import { mapGetters, mapMutations} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                title: '',
                description: '',
                turbo_sms_title: 'TurboSMS',
                turbo_sms_module: 'Turbosms',
                turbo_sms_description: 'Турбо смс',
                np_status: 1,
                turbo_sms_sender: '',
                turbo_sms_api_key: '',
                turbo_secret_key: '',
            },
        }
    },
    computed: {
        ...mapGetters({
            options:'config/options',
        }),
    },
    watch:{
        options(e){
            this.checkOption(e);
        },
    },
    created() {
        this.checkOption(this.options);
    },
    methods: {
        checkOption(res){
            if(res){
                this.form = res.models;
            }
        },
        closePopup() {
            this.$emit('closePopup')
        },
        sendConfirm() {
            if(this.warehouse){
                this.$emit('changeOptions', this.form)
            }else{
                this.$emit('sendOptions', this.form)
            }
        },
        getSenderAddress(){
            this.getCounterpartyAddress({counterparty_id:this.form.np_counterparty_sender});
        },
        ...mapMutations({
            changeShowWarehouse: 'warehouse/changeShowWarehouse'
        })
    },
    destroyed() {
        this.changeShowWarehouse(null);
    }
}
